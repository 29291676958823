import React from 'react'

function TagsFilter({ list, onClick }) {
  return (
    <div className="TagsFilter">
      {(list || []).map((item, i) => <TagsFilterItem {...item} onClick={() => onClick(item)} key={i} />)}
    </div>
  )
}

function TagsFilterItem({ label, active, onClick }){
  return (
    <div className={`TagsFilterItem ${active ? 'TagsFilterItem-active' : ''}`} onClick={onClick}>
      {label}
    </div>
  );
}

export default TagsFilter
