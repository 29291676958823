import DefaultLayout from './../layout/DefaultLayout';
import { UserContainer } from './../com/UserMenu';
import Input from '../com/Input';
import Button from '../com/Button';

function UserPaiement () {
  return (
    <DefaultLayout headerImage="/images/cover.png">
      <UserContainer>
        <h1 className="page-title">Modifier les informations de paiement</h1>
        <div className="userform">
          <Input label="Numéro de carte" />
          <div className="cardinfos">
            <Input label="Date d’expiration" />
            <Input label="Cryptogramme" />
          </div>
          <Input label="Nom du titulaire de la carte" />
          <div className="userformbtn">
            <Button color>Enregistrer</Button>
          </div>
        </div>
      </UserContainer>
    </DefaultLayout>
  )
}

export default UserPaiement
