import { useRecoilState } from "recoil";
import { userState } from "./../states/states";
import { useState, useEffect, useRef } from "react";
import { Logout } from "./Firebase";
import { Link } from "react-router-dom";

function UserMenu({ className, hideSubMenu }) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const ref = useRef(null);

  useEffect(() => {
    const onClick = (e) => {
      if (ref && ref.current) {
        if (!ref.current.contains(e.target) && open) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [open, ref]);

  if (!user) return;
  return (
    <div className={"UserMenu " + className}>
      {user.photoURL ? (
        <img src={user.photoURL} alt="user" onClick={() => setOpen(true)} />
      ) : null}
      <span onClick={() => setOpen(true)}>{user.displayName}</span>
      {hideSubMenu ? null : (
        <>
          <span className="material-icons" onClick={() => setOpen(true)}>
            expand_more
          </span>
          <div ref={ref} className={`submenu ${open ? "submenu-open" : ""}`}>
            <Link
              onClick={() => setOpen(false)}
              className="submenu-item"
              to="/UserInfo"
            >
              {" "}
              <span className="material-icons">account_circle</span> Mon Profil
            </Link>
            <Link
              onClick={() => setOpen(false)}
              className="submenu-item"
              to="/UserPaiement"
            >
              {" "}
              <span className="material-icons">credit_card</span> Mes informations
              de paiement
            </Link>
            <span
              onClick={() => {
                Logout();
                setUser(null);
                setOpen(false);
              }}
              className="submenu-item"
              style={{ color: "#FF6B4A" }}
            >
              {" "}
              <span className="material-icons">logout</span> Déconnexion
            </span>
          </div>
        </>

      )}

    </div>
  );
}

export const UserContainer = ({ children }) => {
  const [user] = useRecoilState(userState);
  if (!user) {
    return <div className="container">{children}</div>;
  }
  return (
    <div className="container">
       <div className="userbtnphoto"><a href="./">Modifier la photo de couverture</a></div>
      <div className="UserContainer">
        
        {user.photoURL ? (
          <div className="userphoto-div">
            <img className="userphoto" src={user.photoURL} alt="user" />
            <span>{user.displayName}</span>
          </div>
        ) : null}
        <div className="w-L">{children}</div>
       
      </div>
     
      

    </div>
  );
};

export const SpoowContainer = ({ children, leftContent }) => {
  return (
    <div className="container">
      <div className="SpoowContainer">
        <div className="left-content">{leftContent}</div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default UserMenu;
