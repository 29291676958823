import DefaultLayout from "./../layout/DefaultLayout";
import { UserContainer } from "./../com/UserMenu";
import useInitList from "./../com/useInitList";
import TagsFilter from "../com/TagsFilter";

function UserCollection({ history }) {
  const [listFilter] = useInitList(
    [
      { label: "Buts", active: true },
      { label: "Passes décisives" },
      { label: "Action déf." },
      { label: "Célébrations" },
      { label: "Arrêts" },
    ],
    (item) => {
      item.select = () => {
        listFilter.forEach((elm) => (elm.active = false));
        item.set({ active: true });
      };
    }
  );

  const [list] = useInitList(
    [...new Array(3)].map((a, i) => ({ id: i + 1 })),
    (item) => {
      item.onClick = () => {
        history.push("/UserCollection/" + item.id);
      };
    }
  );

  return (
    <DefaultLayout headerImage="/images/cover.png">
      <UserContainer>
     
      
        <TagsFilter list={listFilter} onClick={(item) => item.select()} />
        <div className="MarketPlace-list">
          {list.map((item, i) => (
            <MarketPlaceItem item={item} key={i} />
          ))}
        </div>
      
      
      </UserContainer>
        

      
    </DefaultLayout>
  );
}

export function MarketPlaceItem({ item }) {
  return (
    <div className="MarketPlaceItem" onClick={item.onClick}>
      <img src="/images/NFT-Neymar-2.png" alt="MarketPlaceItem" />
      <div className="MarketPlaceItem-name">
        <span>Nom du joueur {item.id}</span>
        <span>#1234-{item.id}</span>
      </div>
      <div className="MarketPlaceItem-dates">BUT - XX/XX/20XX</div>
      <div className="MarketPlaceItem-sep" />
      <div className="MarketPlaceItem-price">
        <span className="partiede">à partie de </span>
        <span className="pricevalue">10{item.id} Euros</span>
      </div>
    </div>
  );
}

export default UserCollection;
