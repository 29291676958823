import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { LoginWithGoogle } from "../com/Firebase";
import Input from "../com/Input";
import Modal from "../com/Modal";
import UserMenu from "../com/UserMenu";
import { userState } from "../states/states";
import Button from "./../com/Button";

function DefaultLayout({ children, headerImage, headerImageM, headerContent, nav, color }) {
  const [user, setUser] = useRecoilState(userState);
  const [visible1, setVisible1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    setLogin(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConnection = (e) => {
    setUser({
      displayName: login,
      photoURL: "/images/profile-picture.png",
    });
    setVisible(false);
    setVisible(false);
  };

  const LoginWithGoogle1 = () => {
    LoginWithGoogle().then((v) => {
      if (v) {
        setVisible(false);
        setVisible1(false);
      }
    });
  };

  return (
    <div className="mainpage">
      {color ? (
        <style>{`.top-header { background-color: transparent !important; } .header-container { background-color: ${color} !important; }`}</style>
      ) : null}
      <div className="header-container">
        { headerImage ? <img src={headerImage} alt="Header-highlight" className="Header-highlight d-none-m" /> : null }
        { headerImageM ? <img src={headerImageM} alt="Header-highlight" className="Header-highlight d-none-d" /> : null }
        <header className="top-header">
          <div className="container">
            <Link to="/" alt="home"><img src="/images/Spoow.png" alt="Spoow" className="logo d-none-m" /></Link>
            <Link to="/" alt="home"><img src="/images/Logo_Mobile.png" alt="Spoow" className="logo d-none-d" /></Link>

            {
              user
                ? (
                  <>
                    <div className="leftmenu d-none-m">
                      <Link to="/MarketPlace">Marketplace</Link>
                      <Link to="/Packs">Packs</Link>
                      <Link to="/UserCollection">Ma collection</Link>
                      
                    </div>
                    
                   <div>
                   <UserMenu className="d-none-m"/>
                  <header className="header d-none-d">
                  <input className="menu-btn" type="checkbox" id="menu-btn" />
                  <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
                  
                  <ul className="menu">
                  <UserMenu hideSubMenu />
                  <Link to="/MarketPlace">Marketplace</Link>
                  <Link to="/Packs">Packs</Link>
                  <Link to="/UserCollection">Ma collection</Link>
                  <Link to="#">Communauté</Link>

                  <Link to="/UserInfo">Mon Profil</Link>
                  <Link to="#" style={{ marginTop:40,color:'#D94733' }}>Déconnexion</Link>
                  </ul>
                  </header>
                  </div>
                    
                  </>
                )
                : (
                  <>
                    <span style={{ flex: 1 }} />
                    <div className="rightmenu">
                      <Button rounded color onClick={() => setVisible(!visible)} >Connexion</Button>
                      <Button rounded onClick={() => setVisible1(!visible1)}>Inscription</Button>
                    </div>
                  </>
                )
            }
          </div>
          {headerContent}
        </header>
      </div>
      {nav ? (
        <div className="container">
          <div className="default-layout-nav">{nav}</div>
        </div>
      ) : null}
      <main>{children}</main>
      <footer>
        <div className="container footer-container">
          <div className="footer-z1">
            <div className="footer-logo">
              <img src="/images/Spoow.png" alt="Spoow" className="logo" />
            </div>
            <div className="footer-menu">
              <Link to="/MarketPlace">Marketplace</Link>
              <Link to="/Packs">Packs</Link>
              <Link to="/UserCollection">Ma collection</Link>
            </div>
            <div className="footer-menu">
              <Link to="#">à propos de nous</Link>
              <Link to="#">F.A.Q.</Link>
            </div>
          </div>
          <div>
            <span className="txt-4">Suivez-nous :</span>
            <div className="flexbox gap16">
              <img src="/images/i1.png" alt="Spoow" />
              <img src="/images/i2.png" alt="Spoow" />
              <img src="/images/i3.png" alt="Spoow" />
              <img src="/images/i4.png" alt="Spoow" />
            </div>
          </div>
          <div className="flexbox gap16 footer-z3">
            <Link to="#">Politique de confidentialité</Link>
            <span className="sep" />
            <Link to="#">Mentions légales</Link>
          </div>
        </div>
      </footer>
      <Modal visible={visible} onClose={() => setVisible(!visible)}>
        <div className="auth-form">
          <div  className="auth-form-image d-none-m">
            <img src="/images/Vector1.png" alt="auth"  />
          </div>
          <div className="auth-form-form">
            <span className="auth-form-title">Connexion</span>
            <Input label="Identifiant" onChange={handleLogin} value={login} />
            <Input
              label="Mot de passe"
              onChange={handlePassword}
              value={password}
            />
            <Button
              color
              style={{ height: 50, marginTop: 30 }}
              onClick={handleConnection}
              disabled={!login}
            >
              Connexion
            </Button>
            <span className="passeoublie">Mot de passe oublié</span>
            <div className="formsep">
              <span className="formsepspan" />
              <span>OU</span>
              <span className="formsepspan" />
            </div>
            <Button onClick={LoginWithGoogle1} image="/images/google-1.png" className="btn btn-google"  style={{ height: 50, marginTop: 30, backgroundColor: '#5B86E5', color: '#fff' }} >Se connecter avec Google</Button>
          </div>
        </div>
      </Modal>
      <Modal visible={visible1} onClose={() => setVisible1(!visible1)}>
        <div className="auth-form">
          <div className="auth-form-image">
            <img src="/images/Vector1.png" alt="auth" />
          </div>
          <div className="auth-form-form">
            <span className="auth-form-title">Inscription</span>
            <Button onClick={LoginWithGoogle1} image="/images/google-1.png" className="btn btn-google" style={{ height: 50, margin: '30px 0', backgroundColor: '#5B86E5', color: '#fff' }} >Se connecter avec Google</Button>
            <div className="formsep">
              <span className="formsepspan" />
              <span>OU</span>
              <span className="formsepspan" />
            </div>
            <Input label="Identifiant" />
            <Input label="Adresse mail" />
            <Input label="Confirmation adresse mail" />
            <Input label="Mot de passe" />
            <Button color style={{ height: 50, marginTop: 30 }}>
              Inscription
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DefaultLayout;
