import React from 'react'

function ShowMore(props) {
  return (
    <button className="ShowMore" {...props} >
      <span className="material-icons">calendar_view_month</span>
      <span>Afficher plus</span>
    </button>
  )
}

export default ShowMore
