import DefaultLayout from './../layout/DefaultLayout';

function PolitiqueDeConfidentialite() {
  return (
    <DefaultLayout>
      <h1>PolitiqueDeConfidentialite</h1>
    </DefaultLayout>
  )
}

export default PolitiqueDeConfidentialite
