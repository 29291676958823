import DefaultLayout from './../layout/DefaultLayout';
import useInitList from './../com/useInitList';
import { SpoowContainer } from '../com/UserMenu';
import Input from './../com/Input';
import ShowMore from './../com/ShowMore';
import { FilterItem } from './MarketPlace';
import { withRouter } from 'react-router';
import Button from '../com/Button';

function Packs({ history }) {
  const images=['/images/Pack gold 3 1.png','/images/Pack silver 1.png','/images/Pack silver 2.png','/images/Pack gold 3 1.png','/images/Pack silver 1.png','/images/Pack silver 2.png']
  const prices=['50','25','10'];
  const [list] = useInitList([...(new Array(3))].map((a, i) => ({ id: i+1 })), (item) => {
    item.img=images[item.id-1];
    item.price=prices[item.id-1];
    item.onClick = () => {
      history.push('/PackFocus/' + item.id);
    }
  });

  return (
    <DefaultLayout headerImage="/images/cover.png"  nav="Niveau 1 / Market place">
      <SpoowContainer leftContent={<PacksFilter />}>
        <Input label="Recherche..." />
        <div className="MarketPlace-list">
          {list.map((item, i) => <PackItem item={item} key={i} />)}
        </div>
        <div className="centerbox">
          <ShowMore />
        </div>
      </SpoowContainer>
    </DefaultLayout>
  )
}

function PacksFilter(){
  return (
    <div className="MarketPlaceFilter">
      <FilterItem label="Ordre de rareté">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt dolor iste maxime, suscipit inventore enim perspiciatis, magni labore saepe provident ad dolorum? Dicta beatae enim similique eaque repudiandae aut?</p>
      </FilterItem>
    </div>
  )
}

export function PackItem({ item , price }){
  return (
    <div className="MarketPlaceItem" onClick={item.onClick}>
      <img src={item.img} alt="PackItem" />
      <div className="MarketPlaceItem-name">
        <span>Nom du pack {item.id}</span>
      </div>
      <div className="MarketPlaceItem-sep" />
      <div className="MarketPlaceItem-price">
        <span className="pricevalue">{item.price} €</span>
        <Button color style={{ width: '100%' }}>ACHETER</Button>
      </div>
    </div>
  )
}




export default withRouter(Packs)
