import DefaultLayout from './../layout/DefaultLayout';

function FAQ() {
  return (
    <DefaultLayout>
      <h1>FAQ</h1>
    </DefaultLayout>
  )
}

export default FAQ
