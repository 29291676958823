function Button({ rounded, color, children, image, ...props }) {
  return (
    <button
      className={`btn ${rounded ? "btn-rounded" : ""} ${
        color === true ? "btn-color" : ""
      }`}
      style={{ backgroundColor: typeof color === "string" ? color : null }}
      {...props}
    >
      {image ? (
        <span className="img">
          <img src={image} alt="icon" />
        </span>
      ) : null}
      {children}
    </button>
  );
}

export default Button;
