import Button from '../com/Button';
import DefaultLayout from './../layout/DefaultLayout';
import SepColor from './../com/SepColor';
import { useState } from 'react';
import NavLeftRight from '../com/NavLeftRight';
import { withRouter } from 'react-router';
import useInitList from '../com/useInitList';
import { PackItem } from './Packs';
import Modal from "../com/Modal";
import { ReactComponent as Coolicon } from "../assets/coolicon.svg";
import { ReactComponent as Ellipse } from "../assets/ellipse.svg";

function PackFocus () {
  const [visible, setVisible] = useState(false);

  return (
    <DefaultLayout color="#111117" nav="Niveau 1 / Market place / ID NFT">
      <div className="container">
        <div className="packheader">
          <img src="/images/Pack gold 2 1.png" alt="Pack gold 2 1" />
          <div className="packdetails">
            <div className="packdetails-title">Nom du pack</div>
            <div className="packdetails-price">50,00 <small><sup>EUROS</sup></small></div>
            <Button color onClick={() => setVisible(true)}>Acheter</Button>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et mauris massa. Pellentesque pellentesque accumsan ante vitae finibus.</p>
          </div>
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(!visible)}>
        <div className="modalVente">
          <h3>
            Votre pack <br /> a été acheté avec succès
          </h3>
          <div className="ellipse">
            <Ellipse height={250} />
            <Coolicon className="checkE" />
          </div>

          <a href="/Packs">Revenir à la collection</a>
        </div>
      </Modal>
      <SepColor />
      <section className="color txtcolor">
        <div className="container">
          <div className="MarketPlaceFocus-subtitle">Détails  du pack</div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel consequat ex, a gravida arcu. Curabitur sed maximus dolor. Maecenas non mi urna. Nunc mattis eros enim, in posuere nunc molestie in. Quisque gravida sodales nisi, eget pulvinar ligula lacinia gravida. Aliquam elementum auctor maximus. Sed rutrum sagittis augue vitae ullamcorper. <br />
            <br />
            Maecenas pretium, risus pulviznar malesuada porta, est metus grzavida tortor, sed ultrices tellus lectus a mauris. <br />
            <br />
            Aliquam fringilla et massa eget hendrerit. Proin consequat purus quis felis maximus dignissim. <br />
            <br />
            Nulla urna est, egestas sit amet metus non, malesuada sagittis mauris. <br />
            Nulla facilisi. Fusce sit amet massa neque. <br />
            Suspendisse vel molestie ante, id semper urna.<br />
            <br />
            Aenean rutrum et dui vitae vehicula. Aenean ac faucibus enim. Nunc lacinia nunc convallis augue condimentum laoreet. Curabitur eleifend turpis turpis, a suscipit elit vehicula sit amet. Vivamus non dolor varius, lobortis ipsum sit amet, hendrerit neque. Maecenas commodo tellus elementum massa tincidunt aliquam vel ac libero.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <PackItemsCarrousel />
        </div>
      </section>
    </DefaultLayout>
  )
}


const PackItemsCarrousel = withRouter(({ history }) => {
  const images=['/images/Pack gold 3 1.png','/images/Pack silver 1.png','/images/Pack silver 2.png','/images/Pack gold 3 1.png','/images/Pack silver 1.png','/images/Pack silver 2.png','/images/Pack gold 3 1.png','/images/Pack silver 1.png','/images/Pack silver 2.png']
  const prices=['50','25','10','50','25','10','50','25','10'];
  const [list] = useInitList([...(new Array(9))].map((a, i) => ({ id: i + 1 })), (item) => {
    item.img=images[item.id-1];
    item.price=prices[item.id-1];
    item.onClick = () => {
      history.push('/PackFocus/' + item.id);
    }
  });
  const [page, setPage] = useState(1);
  const perPage = 4;
  const startAt = (page - 1) * perPage;


  return (
    <div className="MarketPlaceItemsCarrousel noselect">
      <div className="MarketPlaceItemsCarrousel-t">
        <div className="MarketPlaceFocus-title">Packs similaires</div>
        <NavLeftRight total={list.length} perPage={perPage} onChange={setPage} />
      </div>
      <div className="MarketPlaceItemsCarrousel-list">
        {list.slice(startAt, startAt + perPage).map((item, i) => <PackItem item={item} key={page + "-" + i} />)}
      </div>
    </div>
  );
});

export default PackFocus
