import DefaultLayout from "./../layout/DefaultLayout";
import PackDetail from "./../com/PackDetail";
import SepColor from "./../com/SepColor";
import useInitList from "../com/useInitList";
import { withRouter } from "react-router";
import { MarketPlaceItem } from "./MarketPlace";
import NavLeftRight from "./../com/NavLeftRight";
import { useState } from "react";

function MarketPlaceFocus(props) {
  const { id } = props.match.params;
  const page = props.match.url.includes("MarketPlace") ? "MarketPlace" : "UserCollecion" ;
  return (
    <DefaultLayout color="#111117" nav="Niveau 1 / Market place / ID NFT">
      <div className="container">
        <PackDetail page={page} />
      </div>
      <SepColor />
      <section className="color txtcolor">
        <div className="container">
          <div className="MarketPlaceFocus-title">Statistiques du match</div>
          <div className="MarketPlaceFocus-subtitle">Statistiques du match</div>
          <br />
          <div className="MarketPlaceFocusContainer">
            <MarketPlaceFocusRow
              c1={<img src="/images/clubs/1.png" alt="club 1" />}
              c2={
                <span className="flexaroundrow MarketPlaceFocus-scores">
                  <span>0</span> <span>:</span> <span>2</span>
                </span>
              }
              c3={<img src="/images/clubs/2.png" alt="club 2" />}
            />
            <MarketPlaceFocusRow c2="BUTS" />
            <MarketPlaceFocusRow
              c2={<span className="MarketPlaceFocus-line"></span>}
              c3={
                <div className="dfcol">
                  <p>Kylian MBAPPE</p>
                  <p>Mauro ICARDI</p>
                </div>
              }
            />
            <MarketPlaceFocusRow c2="FAITS DE JEU" />
            <MarketPlaceFocusRow
              c2={<span className="MarketPlaceFocus-line"></span>}
              c1={
                <div className="dfcol">
                  <p>Dimitri PAYET</p>
                </div>
              }
            />
            <MarketPlaceFocusRow c2="STATISTIQUES DU MATCH" />
            <div className="stats">
              <MarketPlaceFocusRow c1="12" c2="Tirs" c3="9" />
              <MarketPlaceFocusRow c1="4" c2="Tirs cadrés" c3="2" />
              <MarketPlaceFocusRow c1="54%" c2="Possession" c3="46%" />
              <MarketPlaceFocusRow c1="554" c2="Passes" c3="486" />
              <MarketPlaceFocusRow
                c1="83%"
                c2="% Précision de passe"
                c3="85%"
              />
            </div>
          </div>

          <div className="MarketPlaceFocus-subtitle">Information du Joueur</div>
          <div className="MarketPlaceFocus-info">NOM :</div>
          <div className="MarketPlaceFocus-infovalue">Kylian MBAPPE</div>

          <div className="MarketPlaceFocus-info">DATE DE NAISSANCE :</div>
          <div className="MarketPlaceFocus-infovalue">20/12/1998 (23 ans)</div>

          <div className="MarketPlaceFocus-info">POSTE ET NUMÉRO :</div>
          <div className="MarketPlaceFocus-infovalue">Attaquant - n°7</div>
        </div>
      </section>
      <section>
        <div className="container">
          <MarketPlaceItemsCarrousel />
        </div>
      </section>
    </DefaultLayout>
  );
}

function MarketPlaceFocusRow({ c1, c2, c3 }) {
  return (
    <div className="MarketPlaceFocusRow">
      <div className="MarketPlaceFocusRow-c1">{c1}</div>
      <div className="MarketPlaceFocusRow-c2">{c2}</div>
      <div className="MarketPlaceFocusRow-c3">{c3}</div>
    </div>
  );
}

const MarketPlaceItemsCarrousel = withRouter(({ history }) => {
  const [list] = useInitList(
    [...new Array(9)].map((a, i) => ({ id: i + 1 })),
    (item) => {
      item.onClick = () => {
        history.push("/MarketPlace/" + item.id);
      };
    }
  );
  const [page, setPage] = useState(1);
  const perPage = 4;
  const startAt = (page - 1) * perPage;

  return (
    <div className="MarketPlaceItemsCarrousel noselect">
      <div className="MarketPlaceItemsCarrousel-t">
        <div className="MarketPlaceFocus-title">Highlights similaires</div>
        <NavLeftRight
          total={list.length}
          perPage={perPage}
          onChange={setPage}
        />
      </div>
      <div className="MarketPlaceItemsCarrousel-list">
        {list.slice(startAt, startAt + perPage).map((item, i) => (
          <MarketPlaceItem item={item} key={page + "-" + i} />
        ))}
      </div>
    </div>
  );
});

export default MarketPlaceFocus;
