import DefaultLayout from '../layout/DefaultLayout';

function Apropos() {
  return (
    <DefaultLayout>
      <h1>Apropos</h1>
    </DefaultLayout>
  )
}

export default Apropos
