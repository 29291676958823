import React, { useEffect, useState } from 'react'

function NavLeftRight({ total, perPage, onChange }) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    if(onChange) onChange(page);
  }, [total, perPage, onChange, page]);

  const isFirst = page <= 1;
  const isLast = Math.ceil(total / (perPage || 1)) === page;

  return (
    <div className="NavLeftRight noselect">
      <span onClick={() => setPage(Math.max(page - 1, 1))} className={`NavLeftRightbtn material-icons ${isFirst ? 'disablebtn' : ''}`}>arrow_back_ios</span>
      <span className="NavLeftRight-sep"/>
      <span onClick={() => setPage(Math.min(page + 1, Math.ceil(total / (perPage || 1))))} className={`NavLeftRightbtn material-icons ${isLast ? 'disablebtn' : ''}`}>arrow_forward_ios</span>
    </div>
  )
}

export default React.memo(NavLeftRight);
