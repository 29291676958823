import useInitList from "./useInitList";
import { useState } from "react";
import Button from "./Button";

import Modal from "./Modal";
import AchatMenu from "./AchatMenu";

import { ReactComponent as Coolicon } from "../assets/coolicon.svg";

import { ReactComponent as Ellipse } from "../assets/ellipse.svg";
import MisEnVente from "./MisEnVente";

function PackDetail({ page }) {
  const [activeItem, setActiveItem] = useState(null);
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [montant, setMontant] = useState(null);
  const [list] = useInitList(
    [
      { img: "/images/steps/Step 1 Preview.png" },
      { img: "/images/steps/Step 2 Preview.png" },
      { img: "/images/steps/Step 3 Preview.png" },
      { img: "/images/steps/Step 4 Preview.png" },
    ],
    (item) => {
      item.select = () => {
        list.forEach((elm) => (elm.active = false));
        item.set({ active: true });
        setActiveItem(item);
      };
    }
  );

  if (!activeItem) {
    const elm = list.find((elem) => elem.active);
    if (elm) {
      setActiveItem(elm);
    } else if (list.length) {
      list[0].select();
    }
    return null;
  }

  const options = [
    { img: "/images/imgp1.png", value: "Yann", label: "Yann", price: "50,00" ,unit:"€" },
    {
      img: "/images/imgp2.png",
      value: "Thomas",
      label: "Thomas ",
      price: "75,00",
      unit:"€"
    },
    {
      img: "/images/imgp3.png",
      value: "Sophie",
      label: "Sophie ",
      price: "80,00",
      unit:"€"
    },
  ];

  let btn = null;
  if (page === "MarketPlace") {
    btn = (
      <div className="Achatcomp">
        <AchatMenu
          options={options}
          value="100,00" unit="euro"
        />

        <Button color onClick={() => setVisible(true)}>Acheter</Button>
      </div>
    );
  } else {
    if (show) {
      btn = (
        <div className="BtnMiseVente">
          <MisEnVente
            unit="EUROS"
            placeholder="Montant"
            value={montant}
            onChange={(e)=>setMontant(e.target.value)}
          />
        
          <Button
            color
            style={{ marginTop: "1em" }}
            onClick={() => setVisible2(true)}
          >
            Valider
          </Button>
        </div>
      );
    } else {
      btn = (
        <div className="BtnMiseVente">
          <Button color onClick={() => setShow(true)}>
            Mettre en vente
          </Button>
        </div>
      );
    }
  }

  return (
    <div className="PackDetail">
      <div className="PackDetail-items">
        {list.map((item) => (
          <div
            className={
              "PackDetail-item " + (item.active ? "PackDetail-item-active" : "")
            }
            key={item.key}
            onClick={item.select}
          >
            {" "}
            <img src={item.img} alt="img" />{" "}
          </div>
        ))}
      </div>
      <div className="PackDetail-main">
        <img src="/images/Mask Group.png" alt="Mask Group" />
      </div>
      <div className="PackDetail-details">
        <span className="PackDetail-date">07 . 02 . 2021</span>
        <span className="PackDetail-title">Kylian Mbappé</span>
        <span className="PackDetail-ref">#12345 — Rare</span>
        <p>Olympique de Marseille v. Paris S.G.</p>
        <span className="PackDetail-info">But - 09’</span>
        {btn}
        <small>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et
          mauris
          <br /> massa. Pellentesque pellentesque accumsan ante vitae finibus.
        </small>
      </div>
      <Modal visible={visible} onClose={() => setVisible(!visible)}>
        <div className="modalVente">
          <h3>
            Votre highlight <br /> a été acheté avec succès
          </h3>
          <div className="ellipse">
            <Ellipse height={250} />
            <Coolicon className="checkE" />
          </div>

          <a href="/MarketPlace">Revenir à la collection</a>
        </div>
      </Modal>
      <Modal visible={visible2} onClose={() => setVisible2(!visible2)}>
        <div className="modalVente">
          <h3>
            Votre highlight <br /> a été mis en vente avec succès
          </h3>
          <div className="ellipse">
            <Ellipse height={250} />
            <Coolicon className="checkE" />
          </div>

          <a href="/UserCollection">Revenir à la collection</a>
        </div>
      </Modal>
    </div>
  );
}

export default PackDetail;
