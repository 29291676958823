import Input from '../com/Input';
import { SpoowContainer } from '../com/UserMenu';
import DefaultLayout from './../layout/DefaultLayout';
import { useState } from 'react';
import ShowMore from '../com/ShowMore';
import TagsFilter from '../com/TagsFilter';
import useInitList from './../com/useInitList';
import { withRouter } from 'react-router';



function MarketPlace ({ history }) {
  const [listFilter] = useInitList([{ label: 'Buts', active: true }, { label: 'Passes décisives' }, { label: 'Action déf.' }, { label: 'Célébrations' }, { label: 'Arrêts' }], (item) => {
    item.select = () => {
      listFilter.forEach((elm) => elm.active = false);
      item.set({ active: true });
    }
  });

  const [list] = useInitList([...(new Array(9))].map((a, i) => ({ id: i+1 })), (item) => {
    item.onClick = () => {
      history.push('/MarketPlace/' + item.id);
    }
  });

  return (
   <DefaultLayout headerImage="/images/cover.png" nav="Niveau 1 / Market place">
      <SpoowContainer leftContent={<MarketPlaceFilter />}>
        <Input label="Recherche..." />
        <TagsFilter list={listFilter} onClick={item => item.select()} />
        <div className="MarketPlace-list">
          {list.map((item, i) => <MarketPlaceItem item={item} key={i} />)}
        </div>
        <div className="centerbox">
          <ShowMore />
        </div>
      </SpoowContainer>
    </DefaultLayout>
  )
}

export function MarketPlaceItem ({ item }) {
  return (
   
    <div className="MarketPlaceItem" onClick={item.onClick}>
      <img src="/images/NFT-Neymar-2.png" alt="MarketPlaceItem" />
      <div className="MarketPlaceItem-name">
        <span>Nom du joueur {item.id}</span>
        <span>#1234-{item.id}</span>
      </div>
      <div className="MarketPlaceItem-dates">
        BUT - XX/XX/20XX
      </div>
      <div className="MarketPlaceItem-sep" />
      <div className="MarketPlaceItem-price">
        <span className="partiede">à partie de </span>
        <span className="pricevalue">10{item.id} Euros</span>
      </div>
    </div>
  )
}

function MarketPlaceFilter () {
  return (
    <div className="MarketPlaceFilter">
      <FilterItem label="Ordre de rareté">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt dolor iste maxime, suscipit inventore enim perspiciatis, magni labore saepe provident ad dolorum? Dicta beatae enim similique eaque repudiandae aut?</p>
      </FilterItem>
      <FilterItem label="Prix">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt dolor iste maxime, suscipit inventore enim perspiciatis, magni labore saepe provident ad dolorum? Dicta beatae enim similique eaque repudiandae aut?</p>
      </FilterItem>
      <FilterItem label="Ligue">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt dolor iste maxime, suscipit inventore enim perspiciatis, magni labore saepe provident ad dolorum? Dicta beatae enim similique eaque repudiandae aut?</p>
      </FilterItem>
      <FilterItem label="Compétition">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nesciunt dolor iste maxime, suscipit inventore enim perspiciatis, magni labore saepe provident ad dolorum? Dicta beatae enim similique eaque repudiandae aut?</p>
      </FilterItem>
    </div>
  )
}

export function FilterItem ({ label, children }) {
  const [open, setOpen] = useState(false);
  return (
    <div className={`MarketPlaceFilterItem ${open ? 'MarketPlaceFilterItem-open' : ''}`}>
      <div className="MarketPlaceFilterItem-title" onClick={() => setOpen(!open)}>
        {label}
        {open ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}
      </div>
      <div className="MarketPlaceFilterItem-content">
        {children}
      </div>
    </div>
  )
}


export default withRouter(MarketPlace)
