
import { useState, useEffect, useRef } from "react";


function AchatMenu({ className, hideSubMenu ,options,value,unit }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const onClick = (e) => {
      if (ref && ref.current) {
        if (!ref.current.contains(e.target) && open) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [open, ref]);
  const[selected,setSelected]=useState(null);
  const onSelect=(el)=>{
    setSelected(el);
    setOpen(false);
  }


 
  return (
    <div className={"AchatMenu " + className}>
      
      {hideSubMenu ? null : (
        <>
        <span onClick={() => setOpen(true)}>
        <div className="priceAchat">
              <span className="priceSpan">{(selected && selected.price) || value} </span>{" "}
              <span className="unit">{(selected && selected.unit) || unit} </span>
            </div>
        
        
        </span>
          <span className="material-icons" onClick={() => setOpen(true)}>
            expand_more
          </span>

          <div ref={ref} className={`submenu ${open ? "submenu-open" : ""}`}>
            {options.map(el=>(
              <div onClick={()=>onSelect(el)}  key={el.value} className="submenu-item">
                  <img src={el.img} alt="" />
                  <span>{el.label}</span>
                  <span>{el.price} {el.unit}</span>
                  





              </div>


            ))

            }
           
            
          </div>
        </>

      )}

    </div>
  );
}




export default AchatMenu;
