import { useState } from 'react';

function useInitList(_list, initItem){
  const [list, setList] = useState(_list || []);
  list.map((item, i) => {
    item.key = `key-item-${i}`;
    item.set = (obj = {}) => {
      Object.assign(item, obj || {});
      setList([ ...list ]);
    };
    initItem(item);
    return item;
  });
  return [list, setList];
}


export default useInitList;