import DefaultLayout from './../layout/DefaultLayout';

function MentionsLegales() {
  return (
    <DefaultLayout>
      <h1>MentionsLegales</h1>
    </DefaultLayout>
  )
}

export default MentionsLegales
