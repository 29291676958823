import { atom } from "recoil"

export const userState = atom({
  key: 'userState',
  default: null
});

export const userProfile = atom({
  key: 'userProfile',
  default: null
});

export const userReadyState = atom({
  key: 'userReadyState',
  default: false
});