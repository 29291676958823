
function Modal({ visible, children, onClose }) {
  return (
    <div className={`modal ${visible ? 'modal-visible' : ''}`}>
      <div className="modal-body">
        <button className="modal-btn-close" onClick={onClose}><span className="material-icons">close</span></button>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
