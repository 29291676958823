import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { memo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userProfile, userReadyState, userState } from '../states/states';

const firebaseConfig = {
  apiKey: "AIzaSyAude8m_Xl-hvNF9GZ7hFTemuGkKlaVyl4",
  authDomain: "spoow-nft.firebaseapp.com",
  projectId: "spoow-nft",
  storageBucket: "spoow-nft.appspot.com",
  messagingSenderId: "882915891026",
  appId: "1:882915891026:web:75ed842ee2b5bd8f8c0c8b"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);


function getProfileFromUserObject (user, profile = {}) {
  const data = { ...profile };
  if (user) {
    if (user.displayName) data.name = user.displayName;
    if (user.email) data.email = user.email;
    if (user.photoURL) data.photo = user.photoURL;
  }

  if (!data.photo) {
    data.photo = '/images/profile-picture.png';
  }

  return data;
}

export const FirebaseInitCom = memo(({ children }) => {
  const [, setUser] = useRecoilState(userState);
  const [, setUserProfile] = useRecoilState(userProfile);
  const [ready, setUserReady] = useRecoilState(userReadyState);

  useEffect(() => {
    onAuthStateChanged(getAuth(),async user => {
      if (user) {
        const u = JSON.parse(JSON.stringify(user));
        if (u) delete u.stsTokenManager;
        setUserProfile(getProfile(user));
        setUser(u);
      } else {
        setUser(null);
        setUserProfile(null);
      }
      setUserReady(true);
    });
  }, []);

  if (ready) return children;
  return <div className="firebaseloading">Loading ...</div>;
});

async function getProfile(user) {
  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    setDoc(docRef, getProfileFromUserObject(user));
    return getProfileFromUserObject(user);
  }
}

export const LoginWithGoogle = async () => {
  try{
    return await signInWithPopup(getAuth(), new GoogleAuthProvider());
  } catch (error) {}
  return false;
}

export const verifyEmail = () => {
  const user = getAuth().currentUser;
  return user.sendEmailVerification();
}

export const SignUp = async (email, password, name, setError) => {
  return createUserWithEmailAndPassword(getAuth(), email, password)
    .then((resp) => {
      return collection(db, 'users')
      .doc(resp.user.uid)
      .set({ name });
    })
    .then(() => verifyEmail())
    .catch(error => {
      if (!setError) return;
      switch (error && error.code) {
        case 'auth/wrong-password':
          setError('password', error.message);
          break;
        default:
          setError('email', error.message);
          break;
      }
    });
};

export const signIn = (email, password, setError) => {
    return signInWithEmailAndPassword(getAuth(), email, password)
    .catch((error) => {
      if (!setError) return;
      switch (error && error.code) {
        case 'auth/wrong-password':
          setError('password', error.message);
          break;
        default:
          setError('email', error.message);
          break;
      }
    });
}

export const Logout = () => signOut(getAuth());
