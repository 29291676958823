

function MisEnVente({ unit ,...props}) {


  return (
    <div className="miseVente">
      <input type="number" {...props} />
      <span>{unit}</span>
    </div>
  );
}

export default MisEnVente;
