function Input({ label, ...props }) {
  return (
    <div className="input-field">
      {/* <label>{label}</label> */}
      <input placeholder={label} {...props} />
    </div>
  );
}

export default Input;
