import DefaultLayout from './../layout/DefaultLayout';
import { UserContainer } from './../com/UserMenu';
import Input from '../com/Input';
import Button from '../com/Button';

function UserInfo () {
  return (
    <DefaultLayout headerImage="/images/cover.png">
      <UserContainer>
        <h1 className="page-title">Modifier les informations personnelles</h1>
        <div className="userform">
          <Input label="Identifiant" />
          <Input label="Adresse mail" />
          <Input label="Confirmation adresse mail" />
          <Input label="Mot de passe" />
          <div className="userformbtn">
            <Button color>Enregistrer</Button>
          </div>
        </div>
      </UserContainer>
    </DefaultLayout>
  )
}

export default UserInfo
