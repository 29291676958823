import Button from "../com/Button";
import PackDetail from "../com/PackDetail";
import DefaultLayout from "../layout/DefaultLayout"

function Home () {
  return (
    <DefaultLayout headerImage="/images/Header-highlight.png" headerImageM="/images/Header-highlight-m.png" headerContent={(
      <div className="top-header-content">
        <div className="top-header-content-txt">
          <img src="/images/Titre.png" alt="titre" />
          <p>Achète, vend et collectionne des Highlights NFTs sous licences officielles.</p>
          <Button color>Démarrez votre collection</Button>
        </div>
        <img src="/images/img1.png" className="d-none-m" alt="titre" style={{ maxWidth: '41%' }} />
        <div style={{position:"relative" , marginBottom:70}}>
        <img src="/images/img1-m.png" className="d-none-d img1-m" alt="titre" />
        <img src="/images/NFT-Neymar-2.png" className="d-none-d positionimg" alt="titre" />
        </div>
        
        
      </div>
    )}>
      <section className="color s1">
        <div className="flexbox-center">
          <p className="txt-1">Crée ta propre collection avec les<br />highlights qui t’ont fait rêver.</p>
        </div>
        <div className="flexbox-center gap200">
          <img src="/images/Bronze-NFT.png" alt="Bronze-NFT" />
          <img src="/images/Silver-NFT.png" alt="Silver-NFT" />
          <img src="/images/Gold-NFT.png" alt="Gold-NFT" />
        </div>
      </section>
      <section>
        <div className="flexbox-center flexbox-center-mobile gap128">
          <p className="txt-2">Choisis ton pack et obtiens les<br />highlights les plus convoités<br />de la saison.</p>
  
          <img src="/images/Pack gold 2 1.png" alt="gold-NFT" />
        </div>
      </section>
      <section className="color s1">
        <div className="flexbox-center flexbox-center-mobile gap200">
          <img src="/images/Visuel-Trading.png" alt="Visuel-Trading" />
          <p className="txt-2 d-none-m">Échange avec<br />les fans pour agrandir<br />ta collection.</p>
          <p className="txt-2 d-none-d">Échange avec les fans pour agrandir ta collection.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <PackDetail id={1} />
        </div>
      </section>
      <section className="color s2 s2-mobile">
        <h2>
          <img src="/images/Vector.png" alt="Vector" />
          <span>Nos clubs partenaires</span>
          <img src="/images/Vector.png" alt="Vector" />
        </h2>
        <div className="flexbox-center gap16 d-none-m">
          {[...(new Array(10))].map((e, i) => <img key={i} src={`/images/clubs/${i + 1}.png`} alt="club" />)}
        </div>
        <div className="flexbox-center gap16 d-none-m">
          {[...(new Array(10))].map((e, i) => <img key={i} src={`/images/clubs/${i + 11}.png`} alt="club" />)}
        </div>

        <div className="flexbox-center gap16 d-none-d bloc-partenaire">
          {[...(new Array(20))].map((e, i) => <img key={i} src={`/images/clubs/${i + 1}.png`} alt="club" />)}
        </div>
      </section>
    </DefaultLayout>
  )
}

export default Home
