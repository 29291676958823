import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Home, MarketPlace, MarketPlaceFocus, Packs, PackFocus, UserInfo, UserCollection, UserPaiement, Apropos, MentionsLegales, PolitiqueDeConfidentialite, FAQ } from './pages';
import { FirebaseInitCom } from './com/Firebase';
import ScrollToTop from './com/ScrollToTop';

function App () {
  return (
    <RecoilRoot>
      <FirebaseInitCom>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/MarketPlace" exact component={MarketPlace} />
            <Route path="/MarketPlace/:id" exact component={MarketPlaceFocus} />
            <Route path="/UserCollection/:id" exact component={MarketPlaceFocus} />
            <Route path="/Packs" exact component={Packs} />
            <Route path="/PackFocus/:id" exact component={PackFocus} />
            <Route path="/UserInfo" exact component={UserInfo} />
            <Route path="/UserCollection" exact component={UserCollection} />
            <Route path="/UserPaiement" exact component={UserPaiement} />
            <Route path="/Apropos" exact component={Apropos} />
            <Route path="/MentionsLegales" exact component={MentionsLegales} />
            <Route path="/PolitiqueDeConfidentialite" exact component={PolitiqueDeConfidentialite} />
            <Route path="/FAQ" exact component={FAQ} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </FirebaseInitCom>
    </RecoilRoot>
  );
}

export default App;
